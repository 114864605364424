<div class="page">
  <back-button (click)="closeConnectionAndGoBack()"></back-button>
  <company-info [company]="company"></company-info>
  <div class="content">
    <div class="app-container" *ngIf="url">
      <img src="../../../assets/screen.png" alt="" class="screen" />
      <iframe [src]="url" frameborder="0" id="app-frame"></iframe>
    </div>
    <div class="export-container">
      <h1 class="export">Användardata</h1>
      <h4 class="breadtext-section">AnvändarId:</h4>
      <h4 class="breadtext">{{ company.ownerId }}</h4>
      <h4 class="breadtext-section">FöretagsId:</h4>
      <h4 class="breadtext">{{ company.id }}</h4>
      <h4 class="breadtext-section">Inloggningsmail:</h4>
      <h4 class="breadtext">{{ company.adminPortalDisplayData.loginEmail }}</h4>
      <div *ngIf="companyInfo$ | async as companyInfo">
        <h4 class="breadtext-section">Prenumerationsstatus:</h4>
        <h4 class="breadtext subscriptionInfoTextHandler">{{ companyInfo.adminPortalDisplayData.subInfoString }}</h4>
      </div>

      <div *ngIf="subscriptionLevel$ | async as subscriptionLevel">
        <div *ngIf="subscriptionLevel == 'base' || subscriptionLevel == 'undecided'">
          <h1 class="export">Ge användare PLUS-access:</h1>
          <div class="subscription-params-row">
            <mat-form-field appearance="fill" class="date-picker-container">
              <mat-label>Välj utgångsdatum</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="grantPlusExpirationYear">
              <mat-hint>ÅÅÅÅ/MM/DD</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-checkbox class="is-trial-checkbox" (change)="updateGrantIsTrial($event)">Ska vara gratisperiod</mat-checkbox>
          </div>
          <div class="confirm-button-container">
            <button class="confirm-button" (click)="grantPlusAccess()" [disabled]="grantPlusExpirationYear == null">
              <div class="button-content">
                <p class="button-text">Ge Access</p>
              </div>
            </button>
          </div>
        </div>
      </div>

      <h1 class="export">Export</h1>

      <mat-form-field appearance="outline">
        <mat-label>Räkenskapsår</mat-label>
        <mat-select [formControl]="financialYearControl">
          <mat-option
            *ngFor="let financialYear of financialYears"
            [value]="financialYear"
          >
            {{ financialYear.start | date: "yyyy-MM-dd" }} -
            {{ financialYear.end | date: "yyyy-MM-dd" }}
          </mat-option>
        </mat-select></mat-form-field
      >
      <div class="exports">
        <sie-export
        [token]="companyToken"
          [disabled]="!selectedFinancialYear"
          [selectedFinancialYear]="selectedFinancialYear"
          [company]="company"
        ></sie-export>
      </div>
    </div>
  </div>
</div>
