import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/Services/auth.service';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { FinancialYear } from 'src/app/Models/accounting-row';
import { ApiService } from 'src/app/Services/api.service';
import { Company } from 'src/app/Models/company';
import { UntypedFormControl } from '@angular/forms';
import {Environment} from '@angular/cli/lib/config/workspace-schema';
import {environment} from '../../../environments/environment';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-app-view-page',
  templateUrl: './app-view-page.component.html',
  styleUrls: ['./app-view-page.component.scss'],
})
export class AppViewPageComponent implements OnInit {
  company: Company;
  companyInfo$: BehaviorSubject<Company> = new BehaviorSubject<Company>(null);
  companyToken: string;
  url: SafeUrl;
  financialYears: FinancialYear[];
  financialYearControl: UntypedFormControl = new UntypedFormControl();
  selectedFinancialYear: FinancialYear;
  subscriptionLevel$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  grantPlusExpirationYear: Date = null;
  grantPlusIsTrial: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authentificationService: AuthenticationService,
    public sanitizer: DomSanitizer,
    private api: ApiService,
  ) {}

  ngOnInit(): void {
    const decoded = localStorage.getItem('villainCompany');
    this.company = JSON.parse(decoded);
    this.companyInfo$.next(JSON.parse(decoded) as Company);

    this.financialYearControl.valueChanges.subscribe((fy) => {
      this.selectedFinancialYear = fy;
    });
    this.authentificationService
      .getCompanyToken(this.company.id)
      .subscribe((companyToken) => {
        this.companyToken = companyToken.signed;

        this.getFinancialYears();
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
          `${environment.villainUrl}/login?villainToken=${companyToken.signed}`,
        );
      });
    
      this.updateCompanyInfo();
  }

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME'

  closeConnectionAndGoBack() {
    this.router.navigateByUrl('search');
  }
  getFinancialYears() {

    this.api
      .getFinancialYears(this.company.id, this.companyToken)
      .subscribe((financialYears: FinancialYear[]) => {
        this.financialYears = financialYears;
      });
  }
  sieExport() {
    if (this.selectedFinancialYear) {

      this.api
        .getSieData(this.company.id, this.selectedFinancialYear.id, this.companyToken)
        .subscribe((data) => {
          console.log(data);
        });
    }
  }

  updateCompanyInfo() {
    this.api.getCompanyInfo(this.company.id).subscribe({
      next: (response) => {
        if (response[0] && response[0].subscription) {
          this.subscriptionLevel$.next(response[0].subscription.level);
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  updateGrantIsTrial(event: MatCheckboxChange) {
    this.grantPlusIsTrial = event.checked;
  }

  formatDateToString(date: Date): string {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let monthStr: string = '';
    let dayStr: string = ''
    if (month < 10) {
      monthStr = '0' + month;
    } else monthStr = month.toString();
    if (day < 10) {
      dayStr = '0' + day;
    } else dayStr = day.toString();
    return date.getFullYear() + '-' + monthStr + '-' + dayStr;
  }

  grantPlusAccess() {
    if (this.grantPlusExpirationYear) {
      let formattedDate: string = this.formatDateToString(this.grantPlusExpirationYear);
      this.api.grantUserPlusAccess(this.company.id, formattedDate, this.grantPlusIsTrial).subscribe({
        next: () => {
          this.grantPlusExpirationYear = null;
          this.grantPlusIsTrial = false;
          this.updateCompanyInfo();
          this.api.getCompany(this.company.id).subscribe((company: Company) => {
            this.companyInfo$.next(company);
          });
        },
        error: (error) => {
          console.error(error);
        }
      });
    }
  }
}
